// this is the delete dialog to be rendered when an entry has to be delted
// work to be done: data is successfully deleted from the backend, but we also have to remove it from the front end cache using vanilla JS array methods
import React from "react";
import { AlertDialog } from "@adobe/react-spectrum";
import { success, error } from "@react/react-spectrum/Toast";

import api from "../../api/api";

function DeleteDialog({ url, value }) {
  async function handleAction() {
    // axios.delete request
    await api
      .deleteById("delete", value.id, url)
      .then((response) => {
        //console.log("TOAST DISPLAYED INSERT SUCCESSFULL");
        success("Record deleted successfully!", {
          onClose: () => console.log("close"),
          timeout: 3000,
        });
      })
      .catch((er) => {
        // console.log("toast of error displayed");
        error("Failed to delete record!", {
          onClose: () => console.log("close"),
          timeout: 3000,
        });
      });
  }
  return (
    <AlertDialog
      variant="destructive"
      title="Delete entry"
      primaryActionLabel="Delete"
      cancelLabel="Cancel"
      onPrimaryAction={handleAction}
    >
      This will permanently delete the selected entry. Continue?
    </AlertDialog>
  );
}

export default DeleteDialog;
