import React from 'react'
import PropTypes from 'prop-types'
import PSRForm from '../../../components/PSRForm/PSRForm'

const PSRPage = props => {
    return <PSRForm />
}

PSRPage.propTypes = {}

export default PSRPage