import React, { useState, useContext, useEffect } from "react";
import Delete from "@spectrum-icons/workflow/Delete";
import api from "../../api/api";
import DataUser from "@spectrum-icons/workflow/DataUser";
import {
  Flex,
  SearchField,
  Text,
  ActionButton,
  Divider,
  Dialog,
  Content,
  Form,
  TextField,
  ButtonGroup,
  Button,
  DialogTrigger,
  useDialogContainer,
  Picker,
  Item,
} from "@adobe/react-spectrum";
import { success, error } from "@react/react-spectrum/Toast";
import {
  Cell,
  Column,
  Row,
  TableView,
  TableBody,
  TableHeader,
} from "@react-spectrum/table";
import { ProgressCircle } from "@adobe/react-spectrum";
import Pagination from "@react/react-spectrum/Pagination";
import Provider from "@react/react-spectrum/Provider";
import { PickerContext } from "../../contexts/GlobalState";
import DeleteDialog from "./DeleteDialog";

function CompanyDialog({ companyId, companyName }) {
  let dialog = useDialogContainer();
  const { setDefaultData } = useContext(PickerContext);
  const [companyNameInput, setCompanyNameInput] = useState(companyName || "");

  async function handleSubmit() {
    if (!companyNameInput) {
      error("Please enter the company name");
      return;
    }

    try {
      if (companyId) {
        // If companyId exists, it's an update operation
        await api.updateCompany({ id: companyId, companyName: companyNameInput });
      } else {
        // Otherwise, it's an add operation
        await api.addCompany({ companyName: companyNameInput });
      }

      success("Record successfully saved!", {
        onClose: () => console.log("close"),
        timeout: 3000,
      });

      const res = await api.fetchAllOptions();
      setDefaultData(res);
    } catch (error) {
      error("Failed to save record!", {
        onClose: () => console.log("close"),
        timeout: 3000,
      });
    }
  }

  return (
    <Dialog>
      <Divider />
      <Content>
        <Form labelPosition="side" width="100%">
          <TextField
            autoFocus
            isRequired
            value={companyNameInput}
            label="Company Name"
            onChange={setCompanyNameInput}
          />
        </Form>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button
          variant="cta"
          onPress={() => {
            console.log("submit done");
            handleSubmit();
          }}
        >
          Save
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}


function Company() {
  let [dialogOpen, setDialogOpen] = useState(false); // dialog is open or close
  const [isLoading, setIsLoading] = useState(false);
  const values = useContext(PickerContext);
  const { client, pageOptions, defaultData } = values;
  let [rows, setRows] = useState(defaultData.clients);
  let [pageCount, setPageCount] = useState(1);
  let [currentPage, setCurrentPage] = useState(1);
  let [pageSize, setPageSize] = useState(5); //posts per page to be displayed
  let [searchTerm, setSearchTerm] = useState("");
  let [currentList, setCurrentList] = useState([]);



  useEffect(() => {
    if (searchTerm === "") {
      //display all results paginated and
      setIsLoading(true);
      console.log("no search is there : ");
      if (defaultData.clients) {
        console.log(defaultData.clients.length);
        if (pageSize === 1) {
          // this means its all records to be displayed at once
          setPageCount(1);
          setCurrentList(rows);
          setCurrentPage(1);
        } else {
          setPageCount(
            defaultData.clients.length !== 0
              ? Math.ceil(defaultData.clients.length / pageSize)
              : 0
          );
          setCurrentList(
            defaultData.clients.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          );
        }
      }

      setIsLoading(false);
      // no filter to be applied
    } else {
      // slice the cache list and then display the filtered results
      setIsLoading(true);
      console.log("search is happening : " + searchTerm);
      let searchList = defaultData.clients.filter((item) => {
        if (searchTerm === "") return item;
        else if (
          item.client_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
          return item;
        console.log(item);
      });

      setPageCount(
        searchList.length !== 0 ? Math.ceil(searchList.length / pageSize) : 0
      );
      setCurrentPage(1);
      setCurrentList(
        searchList.length !== 0
          ? searchList.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          )
          : []
      );
      setIsLoading(false);
    }
    // DEPENDENCIES, i.e., when the page should be re-rendered
  }, [searchTerm, currentPage, pageSize, defaultData.clients]);

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate />;
  }

  return (
    <>
      <br />
      {/* <h2>Here search field</h2> */}
      <Flex justifyContent="space-between">
        <Flex gap="size-150">
          <SearchField
            placeholder="Search Company"
            width="size-6000"
            maxWidth="100%"
            value={searchTerm}
            onChange={setSearchTerm}
            onSubmit={setSearchTerm}
          ></SearchField>
          <Picker
            items={pageOptions}
            selectedKey={pageSize}
            onSelectionChange={(selected) => setPageSize(selected)}
          >
            {(item) => <Item>{item.name}</Item>}
          </Picker>
        </Flex>
        <DialogTrigger onOpenChange={setDialogOpen}>
          <ActionButton>
            <DataUser />
            <Text>Add Company</Text>
          </ActionButton>
          <CompanyDialog />
        </DialogTrigger>
      </Flex>

      <br />

      {defaultData ? (
        <>
          <TableView aria-label="Display Client Names">
            <TableHeader>
              <Column>
                <Text>Client</Text>
              </Column>

              <Column>
                <Text>Actions</Text>
              </Column>
            </TableHeader>
            <TableBody>
              {currentList.map((psr) => {
                return (
                  <Row key={psr.id}>
                    <Cell>
                      <Text>{psr.client_name}</Text>
                    </Cell>

                    <Cell>
                      <Flex gap="size-100">
                        <DialogTrigger onOpenChange={setDialogOpen}>
                          <ActionButton>Edit</ActionButton>
                          <CompanyDialog companyName={psr.client_name} companyId={psr.id}>

                          </CompanyDialog>
                        </DialogTrigger>
                        <Divider size="S" orientation="vertical" />

                        {/* here, pass props of which table to access as well the id of which record to be deleted */}
                        <DialogTrigger onOpenChange={setDialogOpen}>
                          <ActionButton>
                            <Delete />
                          </ActionButton>
                          <DeleteDialog
                            url="client"
                            value={{
                              id: psr.client_id,
                            }}
                          />
                        </DialogTrigger>
                      </Flex>
                    </Cell>
                  </Row>
                );
              })}
            </TableBody>
          </TableView>
          <br />
          {/* this is the pagination taken from the OLD SPECTRUM docs (corp.adobe), so haev to wrap in its provider as well
            pagination will not be rendered if number of pages is only 1 or 0 */}

          {pageCount <= 1 ? (
            ""
          ) : (
            <Provider>
              <Pagination
                aria-label="Table pagination"
                variant="explicit"
                mode="primary"
                defaultpage={currentPage}
                totalPages={pageCount}
                onChange={(value) => {
                  setCurrentPage(value);
                }}
                onNext={() => {
                  setCurrentPage(currentPage + 1);
                }}
                onPrevious={() => {
                  setCurrentPage(currentPage - 1);
                }}
              />
            </Provider>
          )}
        </>
      ) : (
        <Text>No results found</Text>
      )}

      {/* <h2>Here search results table</h2> */}
    </>
  );
}

export default Company;
