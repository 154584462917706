import {
  ActionButton,
  Button, Flex, ProgressCircle, SearchField, StatusLight,
  Text
} from "@adobe/react-spectrum";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import "../../../styles.css";

import { View } from "@adobe/react-spectrum";
import {
  Cell,
  Column,
  Row, TableBody,
  TableHeader, TableView
} from "@react-spectrum/table";
import Pagination from "@react/react-spectrum/Pagination";
import api from "../../../api/api";
import ViewDetail from "@spectrum-icons/workflow/ViewDetail";
import { PickerContext } from "../../../contexts/GlobalState";

const SearchPsr: React.FC<{}> = (): any => {
  let history = useHistory();
  // useHistory hook is used to prevent refresh of the webpage when a button is clicked for routing

  // pagination logic
  const { defaultData } = useContext(PickerContext);
  let [search, setSearch] = React.useState("");
  let [allPsrs, setAllPsrs] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const lastPost = currentPage * pageSize;
  const firstPost = lastPost - pageSize;
  const currentList = allPsrs.slice(firstPost, lastPost);
  const pageCount = allPsrs ? Math.ceil(allPsrs.length / pageSize) : 0;
  var pages = [];
  for (var i = 1; i <= pageCount; i++) {
    pages.push(i);
  }

  useEffect(() => {
    if (!search) return;
    let isSubscribed = true;
    (async () => {
      setIsLoading(true);
      const search_response = await api.globalSearch(search)
      if (isSubscribed) {
        console.log(search_response);
        setAllPsrs(search_response.data);
        setIsLoading(false);
      }
    })();
    return () => {
      isSubscribed = false
    };
  }, [search]);

  const renderTable = () => {
    if (search !== "" && currentList !== null && currentList.length !== 0) {
      return <>
        <TableView>
          <TableHeader>
            <Column><Text>PSR ID</Text></Column>
            <Column><Text>PSR Name</Text></Column>
            <Column><Text>Client Name</Text></Column>
            <Column><Text>Project Classification</Text></Column>
            <Column><Text>Engineer</Text></Column>
            <Column><Text>Enabled</Text></Column>
            <Column><Text>Actions</Text></Column>
          </TableHeader>
          <TableBody>
            {
              currentList.length !== 0 ? currentList.map((psr: any) => {
                return (
                  <Row>
                    <Cell>
                      <Text>{psr.process_id}</Text>
                    </Cell>
                    <Cell>
                      <Text>{psr.process_name}</Text>
                    </Cell>
                    <Cell>
                      <Text>{psr.client_name}</Text>
                    </Cell>
                    <Cell>
                      <Text>{psr.classification_name}</Text>
                    </Cell>
                    <Cell>
                      <Text>{psr.primaryEngineer_id? 
                      defaultData.engineers
                      .filter((item) => item.id === psr.primaryEngineer_id)
                      .map((engineer) => engineer.name):
                      ""}</Text>
                    </Cell>
                    <Cell>
                      <StatusLight
                        variant={psr.enabled ? "positive" : "notice"}
                      >
                        {psr.enabled ? "Active" : "Disabled"}
                      </StatusLight>
                    </Cell>
                    <Cell>
                      {/* <Link
                        to={`/psr-form/view/${psr.process_id}`}
                        className="link-decor"
                      >
                        <Button variant="primary">View</Button>
                      </Link> */}
                      <ActionButton
                        onPress={() => { history.push(`/psr-form/view/${psr.process_id}`); }}
                        width={40}
                        isQuiet
                      >
                        <ViewDetail />
                      </ActionButton>
                    </Cell>
                  </Row>
                );
              }) : <></>}
          </TableBody>
        </TableView>
        {
          pageCount <= 1 ? "" :
            <Pagination
              aria-label="Table pagination"
              variant="explicit"
              mode="primary"
              totalPages={pageCount}
              defaultPage={1}
              onChange={(value: number) => setCurrentPage(value)}
              onNext={() => setCurrentPage(currentPage + 1)}
              onPrevious={() => setCurrentPage(currentPage - 1)}
            />
        }
      </>
    }
  }

  return (
    <>
      <View marginTop="size-1200">
        {/* <Heading>Search</Heading> */}
        <br />
        <Flex
          direction="row"
          alignItems="center"
          gap="size-200"
          marginStart="38%"
          marginTop="1%"
        >
          <SearchField
            placeholder="Search PSR by Company/ID"
            width="size-3600"
            maxWidth="100%"
            onChange={setSearch}
            onClear={() => setSearch("")}
            onSubmit={setSearch}
          ></SearchField>
        </Flex>

        {/* <Button
          variant="cta"
          marginTop="5%"
          marginStart="4%"
          onPress={() => {
            history.push(`/display-company/${search}`);
          }}
        >
          Search
        </Button>
        <Button
          marginTop="5%"
          marginStart="5%"
          variant="cta"
          onPress={() => {
            history.push("/psr-form/add");
          }}
        >
          Add New PSR
        </Button> */}
      </View>
      <br />
      {isLoading ? <ProgressCircle isIndeterminate marginTop="45%" /> : renderTable()}
    </>
  );
}

export default SearchPsr;
