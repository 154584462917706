// when search button is clicked on SearchPsr.js page, it re-routes us to this functional component
// it takes the entire search term sent from the search psr page and displays the result in a table

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import "../../styles.css";
import {
  Text,
  Button,
  ProgressCircle,
  StatusLight,
} from "@adobe/react-spectrum";
import {
  Cell,
  Column,
  Row,
  TableView,
  TableBody,
  TableHeader,
} from "@react-spectrum/table";
import api from "../../api/api";

function ViewPsrCompany() {
  //use axios to fetch all psrs in the system

  let [allPsrs, setAllPsrs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // useLocation used to retrieve the serach Term sent in URL PARAMS
  const { pathname } = useLocation();
  let searchTerm = pathname.replace("/display-company/", "");

  console.log(searchTerm);

  // FETCH PSR FOR THE SEARCH TERM
  useEffect(() => {
    async function fetchPsr() {
      setIsLoading(true);

      await api
        .globalSearch(searchTerm)
        .then((data) => {
          console.log(data);
          setAllPsrs(data);
        })
        .catch((err) => {
          console.log(err);
        });

      setIsLoading(false);
    }

    fetchPsr();
  }, [searchTerm]);

  //console.log(allPsrs);
  return (
    <>
      {isLoading ? (
        <ProgressCircle aria-label="Loading..." isIndeterminate />
      ) : (
        allPsrs.length !== 0 && (
          <TableView>
            <TableHeader>
              <Column>
                <Text>PSR ID</Text>
              </Column>
              <Column>
                <Text>PSR Name</Text>
              </Column>
              <Column>
                <Text>Client Name</Text>
              </Column>
              <Column>
                <Text>Project Classification</Text>
              </Column>
              <Column>
                <Text>Enabled</Text>
              </Column>
              <Column>
                <Text>Actions</Text>
              </Column>
            </TableHeader>
            <TableBody>
              {allPsrs.map((psr) => {
                return (
                  <Row>
                    <Cell>
                      <Text>{psr.process_id}</Text>
                    </Cell>
                    <Cell>
                      <Text>{psr.name}</Text>
                    </Cell>
                    <Cell>
                      <Text>{psr.client_name}</Text>
                    </Cell>
                    <Cell>
                      <Text>{psr.classification_name}</Text>
                    </Cell>
                    <Cell>
                      <StatusLight
                        variant={psr.enabled ? "positive" : "notice"}
                      >
                        {psr.enabled ? "Active" : "Disabled"}
                      </StatusLight>
                    </Cell>
                    <Cell>
                      <Link
                        to={`/psr-form/view/${psr.process_id}`}
                        className="link-decor"
                      >
                        <Button variant="primary">View</Button>
                      </Link>
                    </Cell>
                  </Row>
                );
              })}
            </TableBody>
          </TableView>
        )
      )}
    </>
  );
}

export default ViewPsrCompany;
