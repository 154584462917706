// the setting stab view
import { useState, useContext } from "react";
import { PickerContext } from "../../../contexts/GlobalState";
import React from "react";
import {
  Form,
  TextField,
  Picker,
  Item,
  Flex,
  Button,
} from "@adobe/react-spectrum";

// functional component to be rendered
const PsrSettings = ({
  formData,
  setFormData,
  onClickNext,
  onClickPrev,
  formView,
}) => {
  const next = () => {
    onClickNext(4);
  };

  const prev = () => {
    onClickPrev(2);
  };

  let [isValid, setValid] = useState(false);
  let [value, setValue] = useState(" ");
  let [pumaKey, setPuma] = useState("Yes");
  let [badgerKey, setBadger] = useState("Yes");
  const { defaultData } = useContext(PickerContext);
  const onChange = (newVal) => {
    const regex =
      /^([A-Za-z0-9]+@|http(|s)\:\/\/)([A-Za-z0-9.]+(:\d+)?)(?::|\/)([\d\/\w.-]+?)(\.git.corp.adobe)?$/i.test(
        newVal
      );
    setValid(newVal.length <= 200 && regex ? true : false);
    setValue(newVal);
    setFormData({ ...formData, github_repo: newVal });
  };

  console.log(isValid);
  console.log(value);

  return (
    <>
      <br />
      {defaultData && formView.isReadOnly ? (
        <>
          <Form
            aria-label="Get PSR settings"
            width="60%"
            labelPosition="side"
            labelAlign="start"
            isReadOnly
            isQuiet
          >
            <TextField label="Project Type" />


            <TextField
              label="Crontab"
              value={formData.crontab ? formData.crontab : " "}
            />

            <TextField
              label="Duration"
              value={
                formData.process_duration ? formData.process_duration : " "
              }
            />
            <TextField
              label="Github Repo"
              value={formData.github_repo ? formData.github_repo : " "}
              onChange={onChange}
            />
            <TextField
              label="Scope"
              value={formData.scope ? formData.scope : " "}
              onChange={onChange}
            />
            <TextField
              label="Puma Enabled"
              value={formData.puma_enabled === 1 ? "Yes" : "No"}
            />

          </Form>

        </>
      ) : (
        <>
          {defaultData ? (
            <Form
              aria-label="Get PSR settings"
              width="60%"
              labelPosition="top"
              labelAlign="start"
              marginStart="20%"
              necessityIndicator="icon"
            >

              <TextField
                value={formData.process_duration}
                label="Duration"
                onChange={(value) => {
                  setFormData({ ...formData, process_duration: value });
                }}
              />
              <TextField
                label="Github Repo"
                validationState={isValid ? "valid" : "invalid"}
                value={formData.github_repo}
                onChange={onChange}
              />
              <TextField
                label="Scope"
                value={formData.scope}
                onChange={(value) => {
                  setFormData({ ...formData, scope: value });
                }}
              />
              <Picker
                label="Puma Enabled"
                defaultSelectedKey={pumaKey}
                onSelectionChange={(value) => {
                  setPuma(value === "yes" ? "Yes" : "No");
                  setFormData({
                    ...formData,
                    puma_enabled: value === "yes" ? "1" : "0",
                  });
                  console.log(value);
                }}
              >
                <Item key="yes">Yes</Item>
                <Item key="no">No</Item>
              </Picker>
            </Form>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default PsrSettings;
