import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";
// import SpectrumProvider from "@react/react-spectrum/Provider";
// import { Provider as SpectrumProvider }  from "@react/react-spectrum/Provider";
import { defaultTheme, Provider as ProviderV3 } from "@adobe/react-spectrum";
import Provider from "@react/react-spectrum/Provider";
import IMSWrapper from "../ims/ims-wrapper";
import "./App.css";

import Layout from "../components/Layout/Layout";
import { PickerProvider } from "../contexts/GlobalState";
import { IMSProvider } from "../contexts/IMSContext";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import Home from "../pages/Home/Home";
import Cost from "../pages/Cost/Cost";
import AntPage from "../pages/Ant/AntPage";
import VervetPage from "../pages/VervetPage/VervetPage";
const App: React.FC<{}> = (): any => {



  return (
    <IMSProvider>
      <PickerProvider>
        <ProviderV3 theme={defaultTheme} colorScheme="light">
          <Provider theme="light" toastPlacement="bottom">
            <IMSWrapper>
              <Router>
                <Layout>
                  <Switch>
                    {/* <Route path="/"><Redirect to="/home" /></Route> */}
                    <Route path="/ant"><AntPage /></Route>
                    <Route path="/vervet"><VervetPage /></Route>
                    <Route path="/unauthorized"><ErrorPage /></Route>
                    <Route path="/"><Home /></Route>
                  </Switch>
                </Layout>
              </Router>
            </IMSWrapper>
          </Provider>
        </ProviderV3>
      </PickerProvider>
    </IMSProvider>
  );
};
export default App;
