import ReportAdd from "@spectrum-icons/workflow/ReportAdd";
import React, { useContext, useState } from "react";

import {
  ActionButton, Button,
  ButtonGroup, Content, Dialog, DialogTrigger, Divider, Flex, Form,
  Heading, Text, TextField
} from "@adobe/react-spectrum";

import {
  Cell,
  Column,
  Row, TableBody,
  TableHeader, TableView
} from "@react-spectrum/table";
import { PickerContext } from "../../../contexts/GlobalState";

//functional component
function SuiteID({
  formData,
  setFormData,
  onClickNext,
  onClickPrev,
}) {

  // cache
  const values = useContext(PickerContext);
  const { defaultData } = values;
  let columns = [
    { name: "RSID", uid: "rsid" },
    { name: "dataFeed", uid: "dataFeed" },
  ];
  const [newRSID, setNewRSID] = useState({
    rsid: "",
    dataFeed: ""
  });


  const next = () => { onClickNext(3); };
  const prev = () => { onClickPrev(1); };

  return (
    <>
      <br />
      {/* <h2>Here search field</h2> */}
      <Flex justifyContent="space-between">
        <Flex gap="size-150">
        </Flex>
        <DialogTrigger type="modal">
          <ActionButton variant="primary" width="13%">
            <ReportAdd />
            Add RSID
          </ActionButton>

          {(close) => (
            <Dialog>
              <Heading>Enter RSID details</Heading>
              <Divider />
              <Content>
                <Form>
                  <TextField
                    type="text"
                    label="Report Suite ID"
                    value={newRSID.rsid}
                    onChange={val => setNewRSID({
                      ...newRSID,
                      rsid: val
                    })}
                  />

                  <TextField
                    type="text"
                    label="dataFeed"
                    value={newRSID.dataFeed}
                    onChange={val => setNewRSID({
                      ...newRSID,
                      dataFeed: val
                    })}
                  />
                </Form>
              </Content>
              <ButtonGroup>
                <Button variant="secondary" onPress={close}>
                  Cancel
                </Button>
                <Button variant="cta" onPress={() => {
                  setFormData({
                    ...formData,
                    rsids: [...formData.rsids, newRSID]
                  })
                }} autoFocus>
                  Submit
                </Button>
              </ButtonGroup>
            </Dialog>
          )}
        </DialogTrigger>
      </Flex>

      <br />

      {defaultData && formData ? (
        <>
          <TableView
            marginTop="size-200"
            aria-label="Current RSIDs"
            width="99%"
          >
            <TableHeader columns={columns}>
              {(column) => <Column key={column.uid}>{column.name}</Column>}
            </TableHeader>
            <TableBody items={formData.rsids}>
              {formData.rsids && formData.rsids.map((rsid) => {
                return (
                  <Row>
                    <Cell>
                      <Text>{rsid.rsid ? rsid.rsid : "na"}</Text>
                    </Cell>
                    <Cell>
                      <Text>{rsid.dataFeed ? rsid.dataFeed : "na"}</Text>
                    </Cell>
                  </Row>
                );
              })}
            </TableBody>
          </TableView>
        </>
      ) : (
        <Text>No results found</Text>
      )}
    </>
  );
}

export default SuiteID;
