import {
  Dialog,
  Heading,
  Divider,
  Content,
  Form,
  TextField,
  Button,
  ButtonGroup,
  useDialogContainer,
  Picker,
  Item,
} from "@adobe/react-spectrum";

import React, { useEffect, useState, useContext } from "react";
import { success, error } from "@react/react-spectrum/Toast";

import api from "../../api/api";
import { PickerContext } from "../../contexts/GlobalState";

function AddDialog({ input, url, value, type }) {
  const values = useContext(PickerContext);
  const { roles, setClient, setEngineer, setPct, setServer } = values;
  let [inputValue, setInput] = useState("");

  let [role, setRole] = useState("");
  let dialog = useDialogContainer();
  console.log(``);

  useEffect(() => {
    if (type === "add") {
      setInput("");
    }
    //
    else if (type === "edit") {
      setInput(value.name);
    }
  }, []);

  //
  // to handle submit button
  async function handleSubmit() {
    // let formData = [
    //   {
    //     name: inputValue,
    //     username: inputUser,
    //   },
    // ];
    if (type === "add") {
      await api
        .createNew("add", inputValue, url)
        .then((response) => {
          console.log(response);
          success("Record successfully entered!", {
            onClose: () => console.log("close"),
            timeout: 3000,
          });

          // cache should also be updated whenever a new entry is added, so based on the table type, we're setting the state
          switch (url) {
            case "client":
              setClient((prev) => [
                ...prev,
                {
                  id: response.data.insertId,
                  name: inputValue,
                },
              ]);
              break;
            case "engineer":
              setEngineer((prev) => [
                ...prev,
                {
                  id: response.data.insertId,
                  name: inputValue,
                  // ldap: ldap
                },
              ]);
              break;
            case "pct":
              setPct((prev) => [
                ...prev,
                {
                  id: response.data.insertId,
                  name: inputValue,
                },
              ]);
              break;
            case "server":
              setServer((prev) => [
                ...prev,
                {
                  id: response.data.insertId,
                  name: inputValue,
                },
              ]);
              break;

            default:
              console.log("not entered");
          }
        })
        .catch((er) => {
          error("Failed to enter record!", {
            onClose: () => console.log("close"),
            timeout: 3000,
          });
        });
    }
    //
    else if (type === "edit") {
      await api
        .updateById("update", value.id, inputValue, url)
        .then((response) => {
          success("Record successfully updated!", {
            onClose: () => console.log("close"),
            timeout: 3000,
          });
        })
        .catch((er) => {
          error("Failed to update record!", {
            onClose: () => console.log("close"),
            timeout: 3000,
          });
        });
    }
  }

  // render results
  return (
    <Dialog>
      <Heading>{type === "add" ? "Add" : "Edit"}</Heading>
      <Divider />
      <Content>
        <Form labelPosition="side" width="100%">
          <TextField
            autoFocus
            isRequired
            value={inputValue}
            label={input}
            onChange={setInput}
          />
          {url === "engineer" ? (
            <>
              <Picker
                label="Roles"
                items={roles}
                onSelectionChange={(selected) => setRole(selected)}
              >
                {(item) => <Item>{item.name}</Item>}
              </Picker>
            </>
          ) : (
            ""
          )}
          {/* <TextField label="Last Name" defaultValue="Smith" /> */}
        </Form>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button
          variant="cta"
          onPress={() => {
            console.log("submit done");
            handleSubmit();
            dialog.dismiss();
          }}
        >
          Save
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

export default AddDialog;
