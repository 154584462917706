import React, { createContext, useReducer } from "react";
import { IMS_CONSTANTS, ROLES } from '../configs/secrets';

function reducer(state, action) {
    switch (action.type) {
        case IMS_CONSTANTS.SET_PROFILE:
            return {
                ...state,
                profile: action.payload
            };
        case IMS_CONSTANTS.SET_TOKEN:
            return {
                ...state,
                isSignedInUser: true,
                token: action.payload,
                ready: true
            };
        case IMS_CONSTANTS.SET_READY:
            return {
                ...state,
                ready: action.payload
            };
        case IMS_CONSTANTS.SET_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case IMS_CONSTANTS.SET_REAUTH_TOKEN:
            return {
                ...state,
                reauthToken: action.payload,
                isSignedInUser: true
            };
        case IMS_CONSTANTS.SET_TOKEN_EXPIRED:
            return {
                ...state,
                tokenHasExpired: true,
                isSignedInUser: false
            };
        case IMS_CONSTANTS.SET_INITIALIZED:
            return {
                ...state,
                initialized: action.payload,
            };
        case IMS_CONSTANTS.SET_DB_ROLES:
            return {
                ...state,
                dbRoles: {
                    ...state.dbRoles,
                    [action.payload]: true
                }
            };
        default:
            return state;
    }
}

const initialIMSState = {
    token: null,
    tokenValid: false,
    isSignedInUser: false,
    reauthToken: null,
    tokenHasExpired: false,
    ready: false,
    error: null,
    profile: null,
    initialized: false,
    dbRoles: {},
};

export const IMSContext = createContext({} as any);

export const IMSProvider = (props) => {

    const [state, dispatch] = useReducer(reducer, initialIMSState);

    const values = {
        imsInitialized: state.initialized,
        isLoggedIn: state.isSignedInUser,
        imsProfile: state.profile,
        imsToken: state.token,
        dbRoles: state.dbRoles,
        imsError: state.error,
        imsReady: state.ready,
        isAdmin: state.dbRoles[ROLES.ADMIN],
        isEngineer: state.dbRoles[ROLES.ENGINEER],
        setImsInitialized: (value) => {
            dispatch({ type: IMS_CONSTANTS.SET_INITIALIZED, payload: value });
        },
        setImsProfile: (value) => {
            dispatch({ type: IMS_CONSTANTS.SET_PROFILE, payload: value });
        },
        setError: (value) => {
            dispatch({ type: IMS_CONSTANTS.SET_ERROR, payload: value });
        },
        setImsReady: (value) => {
            dispatch({ type: IMS_CONSTANTS.SET_READY, payload: value });
        },
        setImsToken: (value) => {
            dispatch({ type: IMS_CONSTANTS.SET_TOKEN, payload: value });
        },
        setTokenExpired: (value) => {
            dispatch({ type: IMS_CONSTANTS.SET_TOKEN_EXPIRED, payload: value });
        },
        setReauthToken: (value) => {
            dispatch({ type: IMS_CONSTANTS.SET_REAUTH_TOKEN, payload: value });
        },
        setDbRoles: (value) => {
            dispatch({ type: IMS_CONSTANTS.SET_DB_ROLES, payload: value });
        }
    };

    return (
        <IMSContext.Provider value={values}>
            {props.children}
        </IMSContext.Provider>
    );
};