import { Flex, View } from "@adobe/react-spectrum";
import Add from "@spectrum-icons/workflow/Add";
import Magnify from "@spectrum-icons/workflow/Magnify";
import UserAdmin from "@spectrum-icons/workflow/UserAdmin";
import ViewList from "@spectrum-icons/workflow/ViewList";
import React, { useContext, useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import api from "../../api/api";
import CustomSideNav from "../../components/custom-sidenav/CustomSideNav";
import SearchPsr from "./SearchPsr/SearchPsr";
import ViewAllPsr from "./ViewAllPsr/ViewAllPsr";
import ViewPsrCompany from "../../components/ViewPsrCompany/ViewPsrCompany";
import { ROLES } from "../../configs/secrets";
import { PickerContext } from "../../contexts/GlobalState";
import "../../styles.css";
import AdminPage from "./AdminPage/AdminPage";
import { ProtectedRoute } from "../protected.route";
import PSRPage from "./PSRPage/PSRPage";


const SideNavData = [
  {
    title: "Search PSR",
    path: "/search",
    icon: <Magnify />,
    requiresRoles: [],
  },
  {
    title: "View all PSR",
    path: "/view-all-psr",
    icon: <ViewList />,
    requiresRoles: [],
  },
  {
    title: "Add New PSR",
    path: "/psr-form/add",
    icon: <Add />,
    requiresRoles: [ROLES.ENGINEER],
  },
  {
    title: "Administration",
    path: "/admin",
    icon: <UserAdmin />,
    requiresRoles: [ROLES.ADMIN],
  }
];

const Home: React.FC<{}> = (): any => {
  const values = useContext(PickerContext);
  let { path, url } = useRouteMatch();
  path = path == '/' ? "" : path
  url = url == '/' ? "" : url
  const {
    setClient,
    setDefaultData,
  } = values;
  const location: any = useLocation();
  const accessToken = location.hash;
  const index1 = accessToken.indexOf("access_token=");
  const index2 = accessToken.indexOf("&token_type");
  let authToken: any = null;
  if (index1 === -1 || index2 === -1) {
    authToken = localStorage.getItem("authToken");
  } else {
    authToken = accessToken.slice(index1 + 13, index2);
  }
  if (!!authToken) {
    localStorage.setItem("authToken", authToken);
  }

  useEffect(() => {
    // when the project is first rendered, cache has to be retrieved from the database and set in these variables
    let isSubscribed = true;

    (async () => {
      const response = await api.fetchAllOptions();
      if (isSubscribed) {
        console.log(response);
        setClient(response.clients);
        setDefaultData(response);
      }
    })();
    return () => {
      isSubscribed = false
    }
  }, []);

  return (
    <Flex direction="row" height="size-4000">
      <View
        backgroundColor="gray-100"
        height="100%"
        position="fixed"
        // marginTop="size-600"
        width="size-2500"
      >
        <CustomSideNav SideNavData={SideNavData} urlPrefix={url} />
      </View>
      <View
        // overflow="auto"
        // width="82%"
        marginStart="size-3000"
        backgroundColor="static-white"
        flex
      >

        <div className="displays">
          <Switch>
            <Route exact path="/"><Redirect to="/search" /></Route>
            <Route path={`${path}/search`}><SearchPsr /></Route>
            <Route path={`${path}/psr-form/:type/:id?`}><PSRPage /></Route>
            <Route path={`${path}/display-company`}><ViewPsrCompany /></Route>
            <Route path={`${path}/view-all-psr`}><ViewAllPsr /></Route>
            {/* <Route path={`${path}/ant`}><AntPage /></Route> */}
            {/* <Route path={`${path}/error`}><ErrorPage /></Route> */}
            <ProtectedRoute path={`${path}/admin`} component={AdminPage} />
          </Switch>
        </div>
      </View>
    </Flex>
  );
}

export default Home;