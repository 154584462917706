import { DateField, Form, TextField } from "@adobe/react-spectrum";
import { parseDate } from '@internationalized/date';
import React, { useContext, useState } from "react";
import { PickerContext } from "../../../contexts/GlobalState";
import { IPSRForm } from "../../../models/psr.model";

interface IPSRFormProp {
  formData: IPSRForm
  setFormData: any
  onClickNext: any
  onClickPrev: any
  formView: any
  type: any
}


// functional component
const Accounting = (props: IPSRFormProp) => {
  const {
    formData,
    setFormData,
    formView,
  } = props
  const { defaultData } = useContext(PickerContext);
  const onChange = (field_name,new_val) => {
    // const regex = /^\d*(\.\d{1,2})?$/.test(newVal);
    // setValid(newVal.length <= 13 && regex ? true : false);
    setFormData({ ...formData, [field_name]: new_val });
  };



  return (
    <>
      {defaultData && formData && (
        <Form
          aria-label="Get PSR accounting"
          width="60%"
          labelPosition="top"
          labelAlign="start"
          marginStart="20%"
          necessityIndicator="icon"
          isReadOnly={formView.isReadOnly ? true : false}
        >
          <TextField
            label="PSA DR"
            maxLength={9}
            value={formData.dr_number}
            // validationState={validDR ? "valid" : "invalid"}
            onChange={val => onChange('dr_number', val)}
          />

          <TextField
            label="PSA Link"
            value={formData.psa_link}
            // validationState={validpsa ? "valid" : "invalid"}
            onChange={val => onChange('psa_link', val)}
          />

          <TextField
            label="ACM"
            maxLength={16}
            value={formData.acm}
            onChange={val => onChange('acm', val)}
          />

          <TextField
            label="Revenue"
            maxLength={13}
            value={formData.annual_recurring_revenue}
            // validationState={isValid ? "valid" : "invalid"}
            onChange={val => onChange('annual_recurring_revenue', val)}
          />
          <TextField
            label="Onshore Hours"
            maxLength={13}
            value={formData.onshore_hours}
            // validationState={isValid ? "valid" : "invalid"}
            onChange={val => onChange('onshore_hours', val)}
          />
          <TextField
            label="Offshore Hours"
            maxLength={13}
            value={formData.offshore_hours}
            // validationState={isValid ? "valid" : "invalid"}
            onChange={val => onChange('offshore_hours', val)}
          />
          <TextField
            label="Infrastructure Cost"
            maxLength={13}
            value={formData.infra_cost}
            // validationState={isValid ? "valid" : "invalid"}
            onChange={val => onChange('infra_cost', val)}
          />
          {/* <TextField
            label="Start Date"
            type="date"
            value={formData.startdate}
            onChange={(value) => {
              setFormData({ ...formData, startdate: value });
            }}
          //placeholder="1234"
          />

          <DateField
            label="Expiration1"
            value={formData.expiration_date ? parseDate(formData.expiration_date.substring(0, 10)) : parseDate("1969-12-31")}
            granularity="day"
            onChange={(value) => {
              setFormData({ ...formData, expiration_date: value?.toString().substring(0, 10) });
            }}
          /> */}
        </Form>
      )}
    </>

  );
};

export default Accounting;
