import React from 'react'

const AntPage = props => {
    return (
        <>

            <div>AntPage</div>
            <h3>Work in Progress...</h3>

        </>

    )
}

AntPage.propTypes = {}

export default AntPage