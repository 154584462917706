import Provider from "@react/react-spectrum/Provider";
import { SideNav, SideNavItem } from "@react/react-spectrum/SideNav";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ROLES } from "../../configs/secrets";
import { IMSContext } from "../../contexts/IMSContext";
import "./CustomSideNav.css";

function CustomSideNav(props) {
  const { SideNavData, urlPrefix } = props;
  const values = useContext(IMSContext);
  const { isAdmin, isEngineer } = values;

  console.log("NB", isAdmin);

  return (
    <Provider theme="light">
      <SideNav
        autoFocus
        manageTabIndex
        typeToSelect
        className="nav-menu"
        variant="multiLevel"
        //defaultValue={onReloadPsr}
        height="100%"
      >
        {
          SideNavData.map((item, index) => {
            if (item.requiresRoles && item.requiresRoles.includes(ROLES.ADMIN) && !isAdmin) return <></>;
            if (item.requiresRoles && item.requiresRoles.includes("engineer") && !isEngineer) return <></>;

            if (item.children) return (
              <SideNavItem
                label="AWS Cost"
                key={item.title + index}
                className="nav-text"
                value={item.title}
                icon={item.icon}
                gap="size-10"
              >
                {item.children.map((item, index2) => (
                  <SideNavItem
                    renderLink={(props) => (
                      <>
                        <Link {...props} to={`${urlPrefix}${item.path}`}>
                          {item.icon}
                          {item.title}
                        </Link>
                      </>
                    )}
                    key={item.title + index + '' + index2}
                    className="nav-text"
                    value={item.title}
                    icon={item.icon}
                    gap="size-10"
                  ></SideNavItem>
                ))}
              </SideNavItem>
            );
            return (
              <SideNavItem
                renderLink={(props) => (
                  <Link {...props} to={`${urlPrefix}${item.path}`}>
                    {item.icon}
                    {item.title}
                    {/* {`${urlPrefix}${item.path}`} */}
                  </Link>
                )}
                key={item.title + index}
                className="nav-text"
                value={item.title}
                icon={item.icon}
                gap="size-10"
              ></SideNavItem>
            );
          })}
      </SideNav>
    </Provider>
  );
}

export default CustomSideNav;
