import { Cell, Column, Row, TableBody, TableHeader, TableView } from '@react-spectrum/table';
import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import { Button, Form, Item, Picker, TextField, Well } from '@adobe/react-spectrum';



const VervetPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [vistaData, setVistaData] = useState([]);
  const [rsid, setRSID] = useState("")
  const [dc, setDC] = useState<React.Key>("pnw")

  const [vervetToken, setVervetToken] = useState("")
  let dcOptions = [
    { id: 'pnw', name: 'PNW' },
    { id: 'lon', name: 'LONDON' },
    { id: 'sin', name: 'SIN' },
  ];

  const handleSubmit = () => {
    async function fetchVistas() {
      setIsLoading(true);
      try {
        const vistas: any = await api.fetchVistaRules(rsid, "", vervetToken, dc);
        console.log('Got the vistas', vistas);
        setVistaData(vistas);
      } catch (error) {
        console.error('Failed to fetch vistas:', error);
      }
      setIsLoading(false);
    }
    fetchVistas()
  }

  return (
    <>
      <Well>
        <p>Prerequisites:</p>
        <ol>
          <li>Activate VPN</li>
          <li>Enable CORS plugin</li>
        </ol>
      </Well>
      <Form
        aria-label="Get Vervet Details"
        width="50%"
        labelPosition="top"
        labelAlign="start"
        marginStart="25%"
        necessityIndicator="icon"

      >
        <TextField
          label="RSIDs"
          value={rsid}
          onChange={(value) => {
            setRSID(value);
          }}
        />
        <TextField
          label="Vervet Token"
          value={vervetToken}
          onChange={(value) => {
            setVervetToken(value);
          }}
        />
        <Picker
          label="Data Center"
          items={dcOptions}
          onSelectionChange={selected => setDC(selected)}
        >
          {(item) => <Item key={item.id}>{item.name}</Item>}
        </Picker>
        <Button variant="primary" onPress={handleSubmit}>
          Get Vistas
        </Button>
      </Form>
      {
        vistaData.map((rsid, index) => (
          <TableView aria-label="Vista Rules Table">
            <TableHeader>
              <Column isRowHeader>Rsid</Column>
              <Column isRowHeader>Vista Rule Description</Column>
              <Column align="end">Enabled</Column>
            </TableHeader>
            <TableBody>
              {rsid.data.map((vista, vistaIndex) => (
                <Row key={vistaIndex}>
                  <Cell>{rsid.rsid}</Cell>
                  <Cell>
                    {vista.description}
                  </Cell>
                  <Cell>
                    {vista.enabled ? 'Enabled' : 'Disabled'}
                  </Cell>
                </Row>
              ))}
            </TableBody>
          </TableView>
        ))
      }
    </>
  );
};

export default VervetPage;
