// this is the GLOBAL STATE created for the entire project to prevent multiple api calls
// work to be done: add another array for data_centers

import React, { createContext, useState } from "react";

// creating a context
export const PickerContext = createContext({} as any);

export const PickerProvider = (props) => {
  let [defaultData, setDefaultData] = useState([]);
  let [client, setClient] = useState([]);
  let [pct, setPct] = useState([]);
  let [server, setServer] = useState([]);
  let [engineers, setEngineers] = useState([]);
  let [dataCenter, setDataCenter] = useState([]);
  let [isAdmin, setIsAdmin] = useState(0);
  let [isEngineer, setIsEngineer] = useState(0);
  let [emailId, setEmailId] = useState(" ");
  let [imsProfile, setImsProfile] = useState(null);
  let [roles, setRole] = useState([
    { id: 1, name: "Manager" },
    { id: 2, name: "Team Lead" },
    { id: 3, name: "Contract Owner" },
    { id: 4, name: "Engineer" },
  ]);

  const pageOptions = [
    { id: 5, name: "5" },
    { id: 10, name: "10" },
    { id: 20, name: "20" },

    { id: 50, name: "50" },
    { id: 100, name: "100" },
    { id: 1, name: "All" },
  ];
  const region = [
    { name: "Unassigned" },
    { name: "North America" },
    { name: "EMEA" },
    { name: "APAC" },
  ];

  // collected them in a commoj place for sending it in a provider
  const values = {
    client, setClient,
    engineers, setEngineers,
    roles, setRole,
    pct, setPct,
    server, setServer,
    dataCenter, setDataCenter,
    isAdmin, setIsAdmin,
    isEngineer, setIsEngineer,
    emailId, setEmailId,
    imsProfile, setImsProfile,
    pageOptions,
    region,
    defaultData, setDefaultData,
  };

  return (
    <PickerContext.Provider value={values}>
      {props.children}
    </PickerContext.Provider>
  );
};
