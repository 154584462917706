export const IMS_CONFIG = {
    clientId: "es-puma-prod",
    scopes: "AdobeID,openid,profile,session,additional_info.account_type",
    redirectUri: window.location.href,
    // redirectUri: "https://puma.ea.adobe.net",
    environment: "prod",
    useLocalStorage: false,
    imslibLocation: "https://auth.services.adobe.com/imslib/imslib.min.js",
}

// export const IMS_CONFIG = {
//     clientId: "es-puma-dev",
//     scopes: "AdobeID,openid",
//     redirectUri: "http://localhost:3000/",
//     environment: "stg1",
//     useLocalStorage: false,
//     initialUrl: "http://localhost:3000/",
//     locationUrl: "https://localhost.corp.adobe.com:9000",
//     imslibLocation: "https://auth-stg1.services.adobe.com/imslib/imslib.min.js",
//     imslibThinLocation: "https://auth-stg1.services.adobe.com/imslib/imslib-thin.js",
// }

export const IMS_CONSTANTS = {
    SET_UI_MODE: 'SET_UI_MODE',
    SET_PROFILE: 'IMS_SET_PROFILE',
    SET_READY: 'IMS_SET_READY',
    SET_ERROR: 'IMS_SET_ERROR',
    SET_TOKEN: 'IMS_SET_TOKEN',
    SET_REAUTH_TOKEN: 'IMS_SET_REAUTH_TOKEN',
    SET_TOKEN_EXPIRED: 'IMS_SET_TOKEN_EXPIRED',
    SET_INITIALIZED: 'IMS_SET_INITIALIZED',
    SET_DB_ROLES: 'IMS_SET_DB_ROLES',
}


export const ROLES = {
    ADMIN: "admin",
    USER: "user",
    ENGINEER: "engineer",
    MANAGER: "manager",
    TEAM_LEAD: "team_lead",
    CONTRACT_OWNER: "contract_owner",
}