import React, { useContext, useEffect, useState } from "react";
import DataUser from "@spectrum-icons/workflow/DataUser";
import Delete from "@spectrum-icons/workflow/Delete";
import api from "../../api/api";

// cache and wrapper functions
import {
  Dialog,
  Heading,
  TextField,
  ButtonGroup,
  Button,
  Content,
  Form,
  useDialogContainer,
  Picker,
  ActionButton,
  DialogTrigger,
  Divider,
  Flex,
  Item,
  ProgressCircle,
  SearchField,
  Text,
} from "@adobe/react-spectrum";
import { success, error } from "@react/react-spectrum/Toast";
import {
  Cell,
  Column,
  Row,
  TableBody,
  TableHeader,
  TableView,
} from "@react-spectrum/table";
import Pagination from "@react/react-spectrum/Pagination";
import Provider from "@react/react-spectrum/Provider";
import { PickerContext } from "../../contexts/GlobalState";
// importing created functional components
import DeleteDialog from "./DeleteDialog";

function EngDialog({ roles, type, value }) {
  let dialog = useDialogContainer();
  const { setDefaultData } = useContext(PickerContext);
  const [ldapInput, setLdapInput] = useState(value ? value.ldap : "");
  const [engineerName, setEngineerName] = useState(value ? value.name : "");
  const [selectedRole, setSelectedRole] = useState(value ? value.role : "");

  async function handleSubmit() {
    console.log(engineerName,ldapInput, value)
    if (!engineerName || !ldapInput) {
      error("Please enter all fields");
      return;
    }

    const data = {
      name: engineerName,
      ldap: ldapInput,
      role: selectedRole,
    };

    try {
      if (type === "edit" && value && value.id) {
        await api.updateEngineer(value.id, data);
        success("Record successfully updated!", { timeout: 3000 });
      } else {
        await api.addEngineer(data);
        success("Record successfully added!", { timeout: 3000 });
      }

      const res = await api.fetchAllOptions();
      setDefaultData(res);
      dialog.dismiss();
    } catch (error) {
      console.error("Failed to save record", error);
      error("Failed to save record!", { timeout: 3000 });
    }
  }


  // render results
  return (
    <Dialog>
      <Divider />
      <Content>
        <Form labelPosition="side" width="100%">
          <TextField
            autoFocus
            isRequired
            value={engineerName}
            label="Engineer Name"
            onChange={setEngineerName}
          />

          <TextField
            autoFocus
            isRequired
            value={ldapInput}
            label="LDAP"
            onChange={setLdapInput}
          />

          <Picker
            label="Roles"
            items={roles}
            onSelectionChange={(selected) => setSelectedRole(selected)}
          >
            {(item) => <Item>{item.name}</Item>}
          </Picker>
        </Form>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Cancel
        </Button>
        <Button
          variant="cta"
          onPress={() => {
            console.log("submit done");
            handleSubmit();
            dialog.dismiss();
          }}
        >
          Save
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

function Engineer() {
  let [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const values = useContext(PickerContext);
  const { roles, defaultData, pageOptions } = values;

  let [engineerList, setRows] = useState(defaultData.engineers);
  let [pageCount, setPageCount] = useState(1);
  let [currentPage, setCurrentPage] = useState(1);
  let [pageSize, setPageSize] = useState(10); //posts per page to be displayed
  let [searchTerm, setSearchTerm] = useState("");
  let [currEngineerList, setCurrEngineerList] = useState([]);

  useEffect(() => {
    if (searchTerm === "") {
      //display all results paginated and
      setIsLoading(true);
      console.log("no search is there : ");
      if (defaultData.engineers) {
        if (pageSize === 1) {
          // this means its all
          setPageCount(1);
          setCurrEngineerList(engineerList);
          setCurrentPage(1);
        } else {
          setPageCount(
            defaultData.engineers.length !== 0
              ? Math.ceil(defaultData.engineers.length / pageSize)
              : 0
          );
          setCurrEngineerList(
            defaultData.engineers.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          );
        }
      }

      setIsLoading(false);
      // no filter to be applied
    } else {
      // slice the searchList and then display
      setIsLoading(true);
      console.log("search is happening : " + searchTerm);
      let searchList = defaultData.engineers.filter((item) => {
        if (searchTerm === "") return item;
        else if (item.name.toLowerCase().includes(searchTerm.toLowerCase()))
          return item;
      });

      setPageCount(
        searchList.length !== 0 ? Math.ceil(searchList.length / pageSize) : 0
      );
      setCurrentPage(1);
      setCurrEngineerList(
        searchList.length !== 0
          ? searchList.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          )
          : []
      );
      setIsLoading(false);
    }
  }, [searchTerm, currentPage, pageSize, defaultData]);

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate />;
  }

  return (
    <>
      <br />
      {/* <h2>Here search field</h2> */}
      <Flex justifyContent="space-between">
        <Flex gap="size-150">
          <SearchField
            placeholder="Search Engineer"
            width="size-6000"
            maxWidth="100%"
            value={searchTerm}
            onChange={setSearchTerm}
            onSubmit={setSearchTerm}
          ></SearchField>
          <Picker
            items={pageOptions}
            selectedKey={pageSize}
            onSelectionChange={(selected) => setPageSize(selected)}
          >
            {(item) => <Item>{item.name}</Item>}
          </Picker>
        </Flex>
        <DialogTrigger onOpenChange={setDialogOpen}>
          <ActionButton>
            <DataUser />
            <Text>Add Engineer</Text>
          </ActionButton>
          <EngDialog roles={roles} />
        </DialogTrigger>
      </Flex>

      <br />

      {defaultData ? (
        <>
          <TableView aria-label="Display engineer Names">
            <TableHeader>
              <Column>
                <Text>Engineer</Text>
              </Column>
              <Column>
                <Text>LDAP</Text>
              </Column>
              <Column>
                <Text>Actions</Text>
              </Column>
            </TableHeader>
            <TableBody>
              {currEngineerList.map((psr) => {
                return (
                  <Row key={psr.id}>
                    <Cell>
                      <Text>{psr.name}</Text>
                    </Cell>
                    <Cell>
                      <Text>{psr.email}</Text>
                    </Cell>

                    <Cell>
                      <Flex gap="size-100">
                        <DialogTrigger onOpenChange={setDialogOpen}>
                          <ActionButton>Edit</ActionButton>
                          <EngDialog
                            type="edit"
                            input="Engineer Name"
                            url="engineer"
                            value={{
                              id: psr.id,
                              name: psr.name,
                              ldap: psr.email,
                            }}
                            roles={roles}
                          />
                        </DialogTrigger>
                        <Divider size="S" orientation="vertical" />

                        {/* here, pass props of which table to access as well the id of which record to be deleted */}
                        <DialogTrigger onOpenChange={setDialogOpen}>
                          <ActionButton>
                            <Delete />
                          </ActionButton>
                          <DeleteDialog
                            url="engineer"
                            value={{
                              id: psr.id,
                            }}
                          />
                        </DialogTrigger>
                      </Flex>
                    </Cell>
                  </Row>
                );
              })}
            </TableBody>
          </TableView>
          <br />
          {pageCount <= 1 ? (
            ""
          ) : (
            <Provider>
              <Pagination
                aria-label="Table pagination"
                variant="explicit"
                mode="primary"
                defaultpage={currentPage}
                totalPages={pageCount}
                onChange={(value) => {
                  setCurrentPage(value);
                }}
                onNext={() => {
                  setCurrentPage(currentPage + 1);
                }}
                onPrevious={() => {
                  setCurrentPage(currentPage - 1);
                }}
              />
            </Provider>
          )}
        </>
      ) : (
        <Text>No results found</Text>
      )}
    </>
  );
}

export default Engineer;
