import { Flex } from "@adobe/react-spectrum";
import React from "react";

import TopNavBar from "../topnavbar/TopNavBar";

const Layout: React.FC<any> = (props: any) => {
    return <>
        <TopNavBar />
        <Flex direction="column" gap="size-100">
            <main>
                {props.children}
            </main>
        </Flex>
    </>

}


export default Layout