import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { IMSContext } from "../contexts/IMSContext";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAdmin } = useContext(IMSContext);



  return (
    <Route
      {...rest}
      render={(props) => {
        return (isAdmin) ? <Component {...props} /> : <Redirect to="/error" />;
      }}
    />
  );
};
