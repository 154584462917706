import { useContext, useEffect } from "react";
import React from "react";
import Wait from "@react/react-spectrum/Wait";
import api from "../api/api";
import { IMS_CONFIG, ROLES } from "../configs/secrets";
import { IMSContext } from "../contexts/IMSContext";
import ImsWebHelper from "./ImsWebHelper";
import { useLocation } from "react-router-dom";

declare global {
    interface Window {
        adobeIMS: any;
        adobeid: any;
    }
}

export function isProd(url?: URL): boolean {
    return (url || window.location).hostname === 'puma.ea.adobe.net'
}

//@ts-ignore
export const IMSWrapper: React.FC<Props> = (props) => {

    const { imsToken, imsInitialized, imsProfile, setImsReady, setImsProfile, setImsInitialized, setImsToken, isLoggedIn, setError, setTokenExpired, setReauthToken, setDbRoles } = useContext(IMSContext);

    window.adobeid = {
        client_id: IMS_CONFIG.clientId,
        scope: IMS_CONFIG.scopes,
        locale: "en_US",
        environment: IMS_CONFIG.environment,
        useLocalStorage: false,
        redirect_uri: IMS_CONFIG.redirectUri,
        // option to disable the token validation in testing envs, if the `disable_token_validation` query param is set to true
        // `autoValidateToken` must be false if the standalone token is set from outside via e.g. 'adobeIMS.setStandAloneToken({expirems: 600000, sid: '', token: customToken})'
        autoValidateToken: isProd(),
        logsEnabled: true,
        onAccessToken: (token) => {
            console.log("onAccessToken");
            setImsToken(token);
        },
        onAccessTokenHasExpired: function () {
            console.log("Token Expired...");
            setTokenExpired(true);
            // window.adobeIMS.reAuthenticate();
        },
        onReauthAccessToken: (reauthToken) => {
            console.log("onReauthAccessToken", reauthToken);
            setReauthToken(reauthToken);
        },
        onError: function (errorType, error) {
            console.error(error);
            const imsError = {
                errorType: errorType,
                error: error
            }
            setError(imsError);
            window.adobeIMS.signIn();
        },
        onReady: function (appState) {
            console.log("onReady------------------------------");
            setImsReady(true);
        },
        onProfile: function (profile) {
            console.log('imsProfile', profile);
            setImsProfile(profile);
        }
    };

    useEffect(() => {
        ImsWebHelper.injectAdobeIms().then(() => {
            console.log('adobe ims injected');
            window.adobeIMS.initialize();
            setImsInitialized(true);
        });
    }, []);

    useEffect(() => {
        console.log("IMS Initialized: ", imsInitialized);
        if (imsInitialized && !isLoggedIn) {
            window.adobeIMS.signIn();
        }
        if (imsInitialized && isLoggedIn) {
            window.adobeIMS.getProfile().then((response) => {
                setImsProfile(response);
            });
        }
    }, [imsInitialized]);

    useEffect(() => {
        let isSubscribed = true;
        console.debug("ImsProfile Updated: " + JSON.stringify(imsProfile));
        if (imsProfile && imsProfile.email) {
            console.log("Fetching user roles from DB for " + imsProfile.email);
            (async () => {
                try {
                    const response = await api.getDBProfileDetails(imsProfile.email);
                    if (isSubscribed && response && response.data) {
                        console.log("Got the db role for ", response.data);
                        if (response.data.is_admin == 1) setDbRoles(ROLES.ADMIN);
                        if (response.data.is_es_contact == 1) setDbRoles(ROLES.ENGINEER);
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        console.log("User not found in the database");
                        // Handle the error appropriately, e.g., setDbRoles to null
                        setDbRoles(null);
                    } else {
                        console.error(error);
                    }
                }
            })();
        }
        return () => {
            isSubscribed = false;
        }
    }, [imsProfile]);

    console.log("adobeIMS:", window.adobeIMS);
    console.log("Access Token", imsToken);
    console.log("imsAuth: imsInitialized", imsInitialized, "isLoggedIn", isLoggedIn);

    useEffect(() => {
        if (!!imsToken) {
            console.log('Setting the token', imsToken);
            localStorage.setItem("authToken", JSON.stringify(imsToken));
        }
    }, [imsToken]);

    if (!imsInitialized) return <Wait size={"L"} style={{ "margin": "0 0 1rem 1rem" }} />

    if (isLoggedIn) {
        return props.children;
    } else {
        return <Wait size={"L"} style={{ "margin": "0 0 1rem 1rem" }} />
    }
};

export default IMSWrapper;
