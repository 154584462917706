//import React libraries
import React, { useState, useContext, useEffect } from "react";

//
// import React Spectrum libraries
import ProjectAdd from "@spectrum-icons/workflow/ProjectAdd";
import Delete from "@spectrum-icons/workflow/Delete";

import {
  Flex,
  SearchField,
  Text,
  ActionButton,
  Divider,
  DialogTrigger,
  Picker,
  Item,
} from "@adobe/react-spectrum";

import {
  Cell,
  Column,
  Row,
  TableView,
  TableBody,
  TableHeader,
} from "@react-spectrum/table";
import { ProgressCircle } from "@adobe/react-spectrum";
import Pagination from "@react/react-spectrum/Pagination";
import Provider from "@react/react-spectrum/Provider";
import { PickerContext } from "../../contexts/GlobalState";
// importing created functional components
import AddDialog from "./AddDialog";
import DeleteDialog from "./DeleteDialog";

//
//functional component
function ProjectType() {
  let [dialogOpen, setDialogOpen] = useState(false); // dialog is open or close

  const [isLoading, setIsLoading] = useState(false);

  const values = useContext(PickerContext);
  const { defaultData, pageOptions } = values;

  let [rows, setRows] = useState(defaultData.pct);
  let [pageCount, setPageCount] = useState(1);
  let [currentPage, setCurrentPage] = useState(1);
  let [pageSize, setPageSize] = useState(5); //posts per page to be displayed
  let [searchTerm, setSearchTerm] = useState("");
  let [currentList, setCurrentList] = useState([]);

  useEffect(() => {
    if (searchTerm === "") {
      //display all results paginated and
      setIsLoading(true);
      console.log("no search is there : ");
      if (defaultData.pct) {
        if (pageSize === 1) {
          // this means its all
          setPageCount(1);
          setCurrentList(rows);
          setCurrentPage(1);
        } else {
          setPageCount(
            defaultData.pct.length !== 0
              ? Math.ceil(defaultData.pct.length / pageSize)
              : 0
          );
          setCurrentList(
            defaultData.pct.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          );
        }
      }

      setIsLoading(false);
      // no filter to be applied
    } else {
      // slice the searchList and then display
      setIsLoading(true);
      console.log("search is happening : " + searchTerm);
      let searchList = defaultData.pct.filter((item) => {
        if (searchTerm === "") return item;
        else if (
          item.classification_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
          return item;
      });

      setPageCount(
        searchList.length !== 0 ? Math.ceil(searchList.length / pageSize) : 0
      );
      setCurrentPage(1);
      setCurrentList(
        searchList.length !== 0
          ? searchList.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          : []
      );
      setIsLoading(false);
    }
  }, [searchTerm, currentPage, pageSize, defaultData.pct]);

  if (isLoading) {
    return <ProgressCircle aria-label="Loading…" isIndeterminate />;
  }

  return (
    <>
      <br />
      {/* <h2>Here search field</h2> */}
      <Flex justifyContent="space-between">
        <Flex gap="size-150">
          <SearchField
            placeholder="Search Classification"
            width="size-6000"
            maxWidth="100%"
            value={searchTerm}
            onChange={setSearchTerm}
            onSubmit={setSearchTerm}
          ></SearchField>
          <Picker
            items={pageOptions}
            selectedKey={pageSize}
            onSelectionChange={(selected) => setPageSize(selected)}
          >
            {(item) => <Item>{item.name}</Item>}
          </Picker>
        </Flex>
        <DialogTrigger onOpenChange={setDialogOpen}>
          <ActionButton>
            <ProjectAdd />
            <Text>Add Classification</Text>
          </ActionButton>
          <AddDialog input="Enter New Classification" url="pct" type="add" />
        </DialogTrigger>
      </Flex>

      <br />

      {defaultData ? (
        <>
          <TableView aria-label="Display Classification Names">
            <TableHeader>
              <Column>
                <Text>Classification </Text>
              </Column>

              <Column>
                <Text>Actions</Text>
              </Column>
            </TableHeader>
            <TableBody>
              {currentList.map((psr) => {
                return (
                  <Row key={psr.classification_id}>
                    <Cell>
                      <Text>{psr.classification_name}</Text>
                    </Cell>

                    <Cell>
                      <Flex gap="size-100">
                        <DialogTrigger onOpenChange={setDialogOpen}>
                          <ActionButton>Edit</ActionButton>
                          <AddDialog
                            type="edit"
                            input="Classification Name"
                            url="pct"
                            value={{
                              id: psr.classification_id,
                              name: psr.classification_name,
                            }}
                          />
                        </DialogTrigger>
                        <Divider size="S" orientation="vertical" />

                        {/* here, pass props of which table to access as well the id of which record to be deleted */}
                        <DialogTrigger onOpenChange={setDialogOpen}>
                          <ActionButton>
                            <Delete />
                          </ActionButton>
                          <DeleteDialog
                            url="pct"
                            value={{
                              id: psr.classification_id,
                            }}
                          />
                        </DialogTrigger>
                      </Flex>
                    </Cell>
                  </Row>
                );
              })}
            </TableBody>
          </TableView>
          <br />
          {pageCount <= 1 ? (
            ""
          ) : (
            <Provider>
              <Pagination
                aria-label="Table pagination"
                variant="explicit"
                mode="primary"
                defaultpage={currentPage}
                totalPages={pageCount}
                onChange={(value) => {
                  setCurrentPage(value);
                }}
                onNext={() => {
                  setCurrentPage(currentPage + 1);
                }}
                onPrevious={() => {
                  setCurrentPage(currentPage - 1);
                }}
              />
            </Provider>
          )}
        </>
      ) : (
        <Text>No results found</Text>
      )}

      {/* <h2>Here search results table</h2> */}
    </>
  );
}

export default ProjectType;
